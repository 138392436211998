import React from 'react'
import styled from 'styled-components';
import { FaMapMarkerAlt } from 'react-icons/fa';
import colors from './theme';

import Map from './map';

import {Marker} from 'react-map-gl' 

const OaklandSiteMap = (props) => {

    const { selectedProperty, setSelectedProperty } = props;



    const MapMarker = styled(FaMapMarkerAlt)`
    color: blue;
    height: 200px;
    `
    
    const markerStyleActive = {
        color: "green",
        height: "50px",
        width: "50px"
    }

    const markerStyleInactive = {
        color: "gray",
        height: "30px",
        width: "30px"
    }

    const markerStyle = {
        transform: "scale(2.1)",
        color: 'blue',
        position: "absolute",
        x: "0",
        y: "0",
        cursor: "pointer",
        transition: "linear .2s"
    }
    const markerStyleSmall = {
        position: "absolute",
        x: "0",
        y: "0",
        height: "30px",
        width: "30px",
        color: 'blue',
        cursor: "pointer",
        transition: "linear .2s"
    }

    const PriceBubble = styled.div`
    height: 30px;
    width: 70px;
    font-family: rubik;
    font-weight: 200;
    font-size: 18px;
    background-color: ${props => props.available ? colors.blue : "lightgray"};
    color: ${props => props.active ? `black` : colors.gray};
    border-radius: 15px;
    box-shadow: 1px 2px 3px lightgray;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    transition: all .2s ease-in-out;
    transform: ${props => props.active ? `scale(1.2)` : "scale(1)"};
    &:hover {
        transform: scale(1.2);
        color: black;
    }
    `



    return (
        <Map>
            
            <Marker latitude={37.812311} longitude={-122.283038} href="/18th-and-linden">
                {/* <FaMapMarkerAlt style={selectedProperty === '18th and Linden' ? markerStyle : markerStyleSmall} /> */}
                <PriceBubble  available={true} active={selectedProperty === "18th and Linden"}>$1398</PriceBubble>
            </Marker>
            <Marker latitude={37.81678} longitude={-122.27141} >
                <PriceBubble available={true}>$1358</PriceBubble>
            </Marker>
            <Marker latitude={37.82817} longitude={-122.27226}>
                <PriceBubble available={false}>$1500</PriceBubble> 
            </Marker>
              
        
        </Map>
    )
}

export default OaklandSiteMap;