import React, { useState } from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"



import colors from '../components/theme'

import { graphql, useStaticQuery, Link } from 'gatsby';

import StyledBackgroundSection from '../components/backgroundimage';

import ContactForm from '../components/contact-form';
import GalleryGrid from '../components/gallery-grid';
import PropertySelector from '../components/property-selector';

import styled from 'styled-components';
import LightBox from '../components/lightbox';

import Helmet from 'react-helmet';




const HeroSection = styled.section`

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85vh;
  font-family: rubik;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.3);
 
`

const HeroHeading = styled.h1`
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  font-size: 4em;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 2em;
  } 
`

const HeroSubheading = styled.h3`
  color: white;
  font-weight: 400;
  
  font-size: 2em;
  @media (max-width: 850px){
    text-align: center; 
  }
  @media (max-width: 768px) {
    font-size: 1em;
    width: 75%;
    text-align: center`



const FindMyHomeButton = styled.button`
  width: 12em;
  height: 4em;
  margin: 20px;
  background-color: #545F66;
  transition: transform 0.25s;
  color: ${colors.pink};
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0px 2px 3px solid black;
  position: relative;

  &::before{
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.5);
    opacity: 0;
    border-radius: 5px;
    transition: opacity 500ms;
    }
  &:hover::before {
    opacity: 1;
  }

  &:focus {
    outline: 0;
  }
  @media (max-width: 650px){
    width: 8em;
    height: 4em;
    font-size: 12px;
    padding: 10px;
  }
  `

const ExploreSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23d4f4ef' fill-opacity='0.4' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); 
  align-items: center;
  height: 60vh;
  font-family: rubik;
  @media (max-width: 850px){
    height: 50vh;
  }

`

const ContactUsButton = styled.button`
width: 8em;
  height: 4em;
  margin: 20px;
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 20;
  display: ${props => !props.active && "none"};
  background-color: ${colors.blue};
  transition: transform 0.25s;
  font-family: rubik;
  color: black;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0px 2px 3px solid black;
`

const ExploreHeading = styled.h1`
  color: Black;
  font-weight: 700;
  font-size: 3em;
  @media (max-width: 850px){
    font-size: 2em;
  } 
`

const ExploreContainer = styled.div`
  width: 50vw;
  margin-top: 2em;
  text-align: center;
  @media (max-width: 850px){
    width: 75vw;
  }
`

const ExploreSubheading = styled.h3`
  color: black;
  font-weight: 400;
  font-size: 2em;
  @media (max-width: 700px){
    font-size: 1em;
  }
  `

 

const CallToActionSection = styled.section`

background-color: ${colors.pink};
display: flex;
flex-direction:row;
padding: 60px;
z-index: 60;
justify-content: center;
@media (max-width: 1000px){
  height: 100vh;
}`

const HorizontalDivider = styled.div`
height: 2px;
width: 60%;
display: flex;
background-color: ${colors.gray};
border-radius: 1px;
`

const IndexPage = () => {

  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [tourNow, setTourNow] = useState(true);

  const data = useStaticQuery(graphql`
  query {
    allContentfulPortfolio {
      edges {
        node {
          portfolioPhotos {
            fluid(quality: 65) {
              ...GatsbyContentfulFluid_withWebp
            }
            fixed(width: 400){
              ...GatsbyContentfulFixed_withWebp
            }
          }

          heroImage {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`)






  let map = React.createRef();

  const handleClick = () => {
    if(map.current){
      map.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center"
      })
    }
  }

 

  return(
    <>
    <Helmet>
          <title>Square One | Home</title>
          <meta name="title" content="Square One Living - Everything you need; Nothing You Don't" />
          <meta name="description" content="Elegant spaces for those who want to live close to everything and sacrifice nothing."/>
          <link rel="canonical" href="http://squareone-living.com" />
            
      </Helmet>
    <Layout>
      
      <StyledBackgroundSection fluid={data.allContentfulPortfolio.edges[0].node.heroImage.fluid}>

        <HeroSection>
          <HeroHeading>
            The Best of Both Worlds
          </HeroHeading>
          <HeroSubheading>
            <span style={{color: "#D4F4EF", fontWeight: "700", textShadow: "1px 0 0"}}>Community</span> when you want it, <span style={{color: "#F7DCD4", fontWeight: "700"}}>privacy</span> when you don't.
          </HeroSubheading>
          <FindMyHomeButton onClick = {(e)=>handleClick(e)}>Find My Home</FindMyHomeButton>
          
          
          
        </HeroSection>
        
        
        
      </StyledBackgroundSection>
      <div ref={map} />
      <PropertySelector/>
     

      
      <ExploreSection >
      
      <HorizontalDivider />
        <ExploreContainer>
          <ExploreHeading>
            Beautiful spaces. <br />
            Incredible locations.
          </ExploreHeading>
          <ExploreSubheading>
            SquareOne properties will have you immediately feeling at home, <br/>immersed in thoughtful design that is equal parts functional and inspiring.
          </ExploreSubheading>
        </ExploreContainer>
        
      </ExploreSection>
      <GalleryGrid photos={data.allContentfulPortfolio.edges[0].node.portfolioPhotos} setIsLightBoxOpen={setIsLightBoxOpen} setPhotoIndex={setPhotoIndex} />
      <CallToActionSection >
        <ContactForm modal={false}/>
      </CallToActionSection>

      <LightBox 
      images={data.allContentfulPortfolio.edges[0].node.portfolioPhotos} 
      setIsOpen = {setIsLightBoxOpen} 
      isOpen = {isLightBoxOpen} 
      photoIndex={photoIndex}
      setPhotoIndex={setPhotoIndex}  />
        
     
      
    </Layout>
    </>
  )
};

export default IndexPage
