import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import OaklandSiteMap from './oakland-site-map';
import { graphql, useStaticQuery, Link } from 'gatsby';
import  colors  from './theme';
import Img from "gatsby-image"
import moment from 'moment'



const PropertyLocator = styled.div`
width: 100%;
display: flex;
height: 75vh;
overflow: hidden;
margin-top: 20px;
@media (max-width: 1000px){
  flex-direction: column;
  align-items: center;
}
`

const PropertyListSection = styled.div`
display: flex;
width: 50%;
z-index: 0;
flex-direction: column;
align-items: center;
background-color: #FFF;
height: 100%;
font-family: rubik;
@media (max-width: 1000px){
  width: 100%;
}
`

const PropertyCard = styled.div`
box-sizing: border-box;
z-index: 20;
width: 100%;
height: 100%;
height: 33.3%;
overflow: hidden;
display: flex;
padding: 10px;
border-bottom: 1px solid lightgray;
${props => props.active && `&:hover {
    background-color: #F8F8F8;
    transition: background-color .25s linear;`
}
}
`

const ThumbnailDiv = styled.div`
height: 100%;
width: 50%;
margin:0;
padding:0;
overflow: hidden;
object-position: -200px;
border-radius: 5px 5px 5px 5px;
cursor: pointer;`

const DetailsContainer = styled.div`
display: flex;
height: 100%;
width: 75%;
`

const DetailsDivLeft = styled.div`
width: 60%;
display: flex;
flex-direction: column;
height: 100%;
`

const DetailsDivLeftTop = styled.div`
display: flex;
flex-direction: column;
height: 50%;
width: 100%;
padding: 5px;
align-items: flex-start;
@media (max-width: 550px){
  width: 70%;
}
`

const DetailsDivLeftBottom = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-end;
height: 50%;
width: 100%;
padding: 5px;
align-items: flex-start;
@media (max-width: 550px){
  width: 70%;
}
`

const AmenitiesDiv = styled.div`
display: flex;
justify-content: left;
align-items: center;
width: 100%;
height 40px;
display: flex;
font-family: rubik;
font-size: 11px;
color: gray;
margin: 4px 5px 1.5px 5px;

`
const DetailsDivRight = styled.div`
display: flex;
flex-direction: column;
height: 100%;
width: 40%;
align-items: flex-end;
justify-content: space-between;
@media (max-width: 550px){
    width: 30%;
}
`

const PropertyMap = styled.div`
height: 100%;
width: 100%;
display: flex;
overflow: hidden;
margin-left: 20px;
border-radius: 5px 0px 0px 5px;
@media (max-width: 1000px){
  display: none;
}
`

const PropertyName = styled.h1`
font-family: rubik;
text-transform: uppercase;
font-weight: 200;
font-size: 24px;
margin: 4px 5px 1.5px 5px;
@media (max-width: 550px){
    font-size: 18px;
}
`
const AvailableDate = styled.h3`
font-weight: 200;
font-size: 14px;
margin: 1.5px 5px 1.5px 5px;
@media (max-width: 550px){
    font-size: 10px;
}`

const Pricing = styled.div`
font-weight: 400;
margin: 5px 5px 0px 5px;
font-size: 24px;
@media (max-width: 550px){
  font-size: 16px;
}
`

const From = styled.span`
font-weight: 200;
display: flex;
font-size: 14px;
text-transform: uppercase;
align-items: flex-end;
`

const ExploreButton = styled.button`
width: 120px;
height: 40px;
font-size: 20px;
font-weight: 200;
color: black;
text-transform: uppercase;
border-radius: 3px;
background-color: ${colors.pink};
margin: 5px;
cursor: pointer;

`

const ComingSoonButton = styled.button`
width: 120px;
height: 40px;
font-size: 16px;
font-weight: 200;
color: white;
text-transform: uppercase;
border-radius: 3px;
background-color: ${colors.gray};
margin: 5px;

`



const PropertySelector = (props) => {

    const data = useStaticQuery(graphql`
    query {
        allContentfulProperty {
            edges {
                node {
                    availableNow
                    propertyName
                    propertyThumbnail {
                        fixed(width: 400, quality: 100) {
                            ...GatsbyContentfulFixed_withWebp
                        }
                    }
                    dateAvailable
                    pricingFrom
                    slug
                }
            }
        }
    }
  `)

    const [selectedProperty, setSelectedProperty] = useState(null);

    return (
        <PropertyLocator ref={props.ref}>
            <PropertyListSection>
            {data.allContentfulProperty.edges.sort((a,b) => { return a.node.pricingFrom - b.node.pricingFrom}).map(property => {
                return (
                <PropertyCard active={property.node.availableNow} onMouseOver={(event)=>event && setSelectedProperty(property.node.propertyName)} onMouseLeave={(event)=>event && setSelectedProperty(null)}>
                    <ThumbnailDiv>
                <Link to={property.node.slug}>{property.node.availableNow ? <Img fixed={property.node.propertyThumbnail && property.node.propertyThumbnail.fixed} /> : <Img style={{filter: "grayscale(100%)"}} fixed={property.node.propertyThumbnail && property.node.propertyThumbnail.fixed} />}</Link>
                    </ThumbnailDiv>
                    <DetailsContainer>
                        <DetailsDivLeft>
                            <DetailsDivLeftTop>
                                <PropertyName>{property.node.propertyName}</PropertyName>
                                <AvailableDate>Available {moment(property.node.dateAvailable) < moment() ? "Now" : moment(property.node.dateAvailable).format("MMMM YYYY")}</AvailableDate>
                                
                            </DetailsDivLeftTop>
                            <DetailsDivLeftBottom>
                                <AmenitiesDiv>
                                    Wifi Included &#183; Washer/Dryer in-unit &#183; Private Bathroom &#183; Fully Furnished 
                                </AmenitiesDiv>
                            </DetailsDivLeftBottom>
                        </DetailsDivLeft>
                        <DetailsDivRight>
                            <From>From <Pricing>${property.node.pricingFrom}</Pricing></From>
                            {property.node.availableNow ? <Link to={property.node.slug}><ExploreButton>Explore</ExploreButton></Link> : <ComingSoonButton>Coming Soon</ComingSoonButton>}
                        </DetailsDivRight>
                    </DetailsContainer>
                </PropertyCard>
                )
            })}
            
            </PropertyListSection>
            <PropertyMap>
                <OaklandSiteMap selectedProperty={selectedProperty} setSelectedProperty={setSelectedProperty} />
            </PropertyMap>
        </PropertyLocator>
    )
}

export default PropertySelector;
